export interface IUserState {
  id: number;
  first_name: string;
  allows_write_to_pm: boolean;
  is_premium: boolean;
  language_code: string;
  last_name: string;
  username: string;
}

export const initialUserState: IUserState = {
  id: null,
  first_name: '',
  allows_write_to_pm: false,
  is_premium: false,
  language_code: '',
  last_name: '',
  username: ''
};
