import { Injectable } from '@angular/core';

import { AppConfigService } from 'src/app/services';
import { ApiProvider } from '../api/api';
import { IUserState } from 'src/app/store/state/user.state';

@Injectable({
  providedIn: 'root',
})
export class AuthProvider {
  constructor(
    private env: AppConfigService,
    private api: ApiProvider
  ) {}

  /** Загрузка инфы о пользователе */
  fetchMe(): Promise<IUserState> {
    return this.api.sendRequest('get', 'auth/me');
  }
}
