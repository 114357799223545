import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.routes').then((m) => m.routes),
  },
  {
    path: 'confirm-order',
    loadComponent: () => import('./pages/confirm-order/confirm-order.page').then((c) => c.ConfirmOrderPage)
  },
  {
    path: 'product-review',
    loadComponent: () => import('./pages/product-review/product-review.page').then( m => m.ProductReviewPage)
  }
];
