import { bootstrapApplication } from '@angular/platform-browser';
import { RouteReuseStrategy, provideRouter, withPreloading, PreloadAllModules } from '@angular/router';
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';
import { provideRouterStore } from '@ngrx/router-store';

import { routes } from './app/app.routes';
import { AppComponent } from './app/app.component';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { StoreModule, ActionReducer, MetaReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { reducers } from 'src/app/store/reducers';

import { initialAppState } from './app/store/state/app.state';
import { importProvidersFrom } from '@angular/core';

function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  const rootKeys = Object.keys(initialAppState);
  const keys = rootKeys.map((rootKey) => {
    const subKeys = Object.keys(initialAppState[rootKey]);
    return { [rootKey]: subKeys };
  });
  return localStorageSync({ keys, rehydrate: true })(reducer);
}
const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];


bootstrapApplication(AppComponent, {
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    provideIonicAngular(),
    provideRouter(routes, withPreloading(PreloadAllModules)),
    provideHttpClient(
      withInterceptorsFromDi(),
    ),
    provideRouterStore(),
    importProvidersFrom(
      StoreModule.forRoot(reducers, { metaReducers }),
    ),
  ],
});
