import { Component, OnInit } from '@angular/core';
import { IonApp, IonRouterOutlet } from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import * as icons from 'ionicons/icons';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { AuthProvider } from './providers/auth/auth.provider';
import { IUserState } from './store/state';
import { AppConfigService } from './services';
import { setUser } from './store/actions';
import { StatisticsProvider } from './providers/statistics/statistics';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  standalone: true,
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
  imports: [
    IonApp,
    IonRouterOutlet,
  ],
})
export class AppComponent implements OnInit {
  constructor(
    private env: AppConfigService,
    private authProvider: AuthProvider,
    private statisticsProvider: StatisticsProvider,
    private router: Router,
    private store: Store<{ user: IUserState }>
  ) {
    addIcons({ ...icons });
  }

  async ngOnInit() {
    await this.env.loadAppConfig();
    
    const tg = (window as any).Telegram.WebApp;

    this.authProvider.fetchMe()
      .then((user) => {
        this.store.dispatch(setUser({ user }));
      });

    // записываем посещения юзера
    this.statisticsProvider.recordVisit();

    if (tg.initDataUnsafe.start_param) {
      const productId = tg.initDataUnsafe.start_param;
      this.router.navigate(['/tabs/catalog/product'], { queryParams: { id: productId } });
    }
  }
}
