import { Injectable } from '@angular/core';

import { AppConfigService } from 'src/app/services';
import { ApiProvider } from '../api/api';

@Injectable({
  providedIn: 'root',
})
export class StatisticsProvider {
  constructor(
    private env: AppConfigService,
    private api: ApiProvider
  ) {}

  /** Запись о посещениях приложухи */
  recordVisit(): Promise<void> {
    return this.api.sendRequest('post', 'statistics/record-visit');
  }
}
