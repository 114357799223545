import { createAction, props } from '@ngrx/store';

import { IUserState } from '../state';

export const USER_ACTIONS = {
  SET_USER: 'set:user',
};

export const setUser = createAction(
  USER_ACTIONS.SET_USER,
  props<{ user: IUserState }>(),
);
